import axios from "axios";
import {Blog, Blogs} from "../data/Blog";
import {decodeEntity} from "html-entities";

const defaultParameters = "?key=AIzaSyAdG4u5YD2CZvQTv_hRtaKrmSNWZkY30oU&fetchImages=true";
const postUrl = "https://www.googleapis.com/blogger/v3/blogs/2624039357299718788/posts";
const blogsUrl = postUrl + defaultParameters;
const blogSearchUrl = postUrl + "/search" + defaultParameters;
const blogUrl = (blogId: string) => postUrl + "/" + blogId + defaultParameters;

const imgSrc = (images: any[]) => {
    if (images != null && images.length > 0) {
        return images[0].url;
    }
    return null;
};
const addEllipsis = (text: string, size?: number) => {
    if (!size) {
        size = 150;
    }
    return text.length > size ? text.substring(0, size) + "..." : text;
};

export const getText = (document: any) => {

    let snippet = '';
    for (let i = 0; i < document.documentElement.childNodes.length; i++) {

        const children = document.documentElement.childNodes[i];
        if (children.textContent !== '') {
            snippet = children.textContent;
            break;
        }
    }
    return addEllipsis(snippet);
};

export const cleanup = (content: string) => {
    return decodeEntity(content);
};

const transformToBlog = (item: any): Blog => {

    const document = new DOMParser().parseFromString("<div>" + item.content + "</div>", 'text/html');

    return {
        id: item.id,
        content: item.content,
        img: imgSrc(item.images),
        snippet: getText(document),
        title: addEllipsis(cleanup(item.title), 50),
        url: item.url
    };
};
const transformToBlogs = (data: any): Blogs => {

    const blogs: Blog[] = [];
    if (data.items) {
        data.items.forEach((item: any) => {
            const blog = transformToBlog(item);
            blogs.push(blog);
        });
    }
    return {blogs: blogs, nextPageToken: data.nextPageToken};
};
const withMaxPost = (maxPosts?: number) => maxPosts ? "&maxResults=" + maxPosts : "";
const withLabel = (label?: string | null) => label ? "&labels=" + label : "";
const withPageToken = (nextPageToken?: string | null | undefined) => nextPageToken ? "&pageToken=" + nextPageToken : "";
export const getRecentBlogs = (maxPosts?: number, label?: string | null, nextPageToken?: string | undefined | null): Promise<Blogs> => {
    return axios.get(blogsUrl + withLabel(label) + withMaxPost(maxPosts) + withPageToken(nextPageToken)).then(response => transformToBlogs(response.data));
};
export const search = (maxPosts?: number, q?: string | null, nextPageToken?: string | undefined | null): Promise<Blogs> => {
    const withQuery = q ? "&q=" + q : "";
    return axios.get(blogSearchUrl + withQuery + withMaxPost(maxPosts) + withPageToken(nextPageToken)).then(response => transformToBlogs(response.data));
};
export const getBlog = (blogId: string): Promise<Blog> => {
    return axios.get(blogUrl(blogId)).then(response => transformToBlog(response.data));
};


