import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';
import {
    FloorplanCardData,
    FloorplanSectionSkeleton,
    FloorplansSection, getAllPropertyFilterData
} from "@renaissancerentals/renaissance-component";
import "./assets/FloorplansPage.scss";

import {Helmet} from "react-helmet-async";
import {isNaN} from "lodash";
import {propertyId} from "../services/Api";

const useQuery = () => {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export const FloorplansPage: React.FC = () => {
    let query = useQuery();
    const [floorplans, setFloorplans] = useState<FloorplanCardData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {

        getAllPropertyFilterData().then(properties => {
            const floorplans: FloorplanCardData[] = [];
            properties.filter(property => !property.name.toLowerCase().includes("garage")).forEach(property => {
                property.floorplans.forEach(floorplan => {
                    floorplans.push(floorplan);
                });
            });
            setFloorplans(floorplans);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);
    const getFloorplanIds = () => {
        const floorplanIds = query.get("floorplanIds");
        return floorplanIds ? floorplanIds.split(",") : [];
    }
    const getBedrooms = () => {
        const bedrooms = Number(query.get("bedrooms"));

        if (!isNaN(bedrooms) && bedrooms > -1)
            return bedrooms;
        else
            return undefined;
    }
    const getAvailability = () => {
        const availability = query.get("availability");

        if (availability)
            return availability;
        else
            return undefined;
    }
    return (
        <div className="page-floorplans">
            <Helmet>
                <title>Renaissance Rentals: Floorplans</title>
            </Helmet>
            {
                isLoading ?
                    <FloorplanSectionSkeleton/> :
                    <FloorplansSection floorplans={floorplans}
                                       defaultBedRooms={getBedrooms()}
                                       defaultAvailability={getAvailability()}
                                       defaultFloorplanIds={getFloorplanIds()}
                                       propertyId={propertyId}
                                       title="search apartments"/>
            }
        </div>);
};
