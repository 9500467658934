import React, {useEffect} from "react";
import {Hero} from "../components/hero/Hero";
import {FeatureSection, trackContactClicked} from "@renaissancerentals/renaissance-component";
import './assets/Home.scss';
import leaseIcon from "./assets/icons/lease-icon.png";
import eSignIcon from "./assets/icons/e-sign-icon.png";
import reputableIcon from "./assets/icons/reputable-icon.png";
import tailoredIcon from "./assets/icons/tailored-icon.png";
import {HomeCard} from "../components/card/HomeCard";
import {SpotlightSection} from "../components/spotlight/SpotlightSection";
import {AboutSection} from "../components/sections/AboutSection";
import {NeighborhoodSection} from "../components/neighborhood/NeighborhoodSection";
import {Helmet} from "react-helmet-async";

export const Home: React.FC<HomeProps> = ({showContactModal, setShowContactModal}) => {
    useEffect(() => {
        if (showContactModal) {
            trackContactClicked("renaissance-rentals");
            setShowContactModal(true);
        }
    }, [showContactModal, setShowContactModal]);

    const contactClickHandler = () => {
        trackContactClicked("renaissance-rentals");
        setShowContactModal(true);
    }
    return (
        <div className="div-home">
            <Helmet>
                <title>Bloomington Indiana Apartments - Studios & 1 to 4 bedroom Rentals by Renaissance Rentals</title>
            </Helmet>
            <Hero/>
            <FeatureSection>
                <div className="container">
                    <HomeCard>
                        <img src={leaseIcon} alt="lease"/>
                        <header>Lease Your Way</header>
                        <p>We want you to be comfortable while searching for you next home. That’s why we offer:</p>
                        <ul>
                            <li>Self-guided tours</li>
                            <li>Safe in-person tours</li>
                            <li>Live virtual tours</li>
                            <li>Online videos & 360° tours</li>
                        </ul>
                    </HomeCard>
                    <HomeCard>
                        <img src={eSignIcon} alt="e sign"/>
                        <header>Easy & Transparent Leasing Process</header>
                        <p>We keep it simple for you!
                            You can fill out our online application for Free, at your convenience. You can also e-sign
                            your electronic lease when the time comes. And yes, you can make payments online, day or
                            night.</p>
                    </HomeCard>
                    <HomeCard>
                        <img src={reputableIcon} alt="reputable"/>
                        <header>Fair, Local Reputable</header>
                        <p>For over 30 years, Renaissance has called Bloomington it’s home. We pride ourselves in having
                            the most skilled & professional leasing and maintenance teams in town.</p>

                        <p> We respect and value diversity in both our team and in our local community. </p>
                    </HomeCard>
                    <HomeCard>
                        <img src={tailoredIcon} alt="tailored"/>
                        <header>Tailored for You</header>
                        <p>With over 1000+ unique rental homes to offer, you can choose from affordable studios to
                            custom-built 4-bedroom town homes.
                        </p>
                        <p> Sleep easy knowing all our homes are comfortable, high quality and well maintained.</p>
                    </HomeCard>
                </div>

            </FeatureSection>
            <SpotlightSection/>
            <NeighborhoodSection/>
            <AboutSection contactClickHandler={contactClickHandler}/>
        </div>

    );
};

export interface HomeProps {
    showContactModal?: boolean;
    setShowContactModal: (showContactModal: boolean) => void;
}
